import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AllDataProvider } from "../../DataProvider/DataProvider";
import Button from "@mui/material/Button";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardDoubleArrowUpSharpIcon from "@mui/icons-material/KeyboardDoubleArrowUpSharp";
import EditIcon from "@mui/icons-material/Edit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { AddUser, EditUser } from "../AxiosFetch/AllFetchMethod";
import { AdditionalUses } from "../AdditionalUses/AdditionalUses";
import { gradientPrimary } from "../../../Theme";
const AddEditUsers = () => {
  const {
    Notifi,
    setNotifi,
    setMessage,
    setopenDialogModal,
    postType,
    userRecall,
    setUserRecall,
    particularUser,
  } = AllDataProvider();
  const [loading, setLoading] = useState(false);
  const [UserData, setUserData] = useState({
    user_name: "",
    user_email: "",
    mobile_no: "",
    password: "",
    // gender: "",
    job_post_crud: false,
    resume_search: false,
    download_excel_format: false,
  });

  useEffect(() => {
    if (postType === "Edit") {
      setUserData(particularUser);
      //console.log(particularUser);
    }
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserData((values) => ({ ...values, [name]: value }));
  };
  /////////////////////////////////////////////////////
  let PostVal = {
    ...UserData,
  };
  //console.log(PostVal);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      if (postType === "Add") {
        let { status, data } = await AddUser(PostVal);
        if (status === 200) {
          setUserRecall(!userRecall);
          setopenDialogModal(false);
          setMessage("Added Successfully");
          setNotifi(true);
        } else {
          throw new Error(data.status);
        }
      } else {
        let { status, data } = await EditUser(PostVal);
        if (status === 200) {
          setUserRecall(!userRecall);
          setopenDialogModal(false);
          setMessage("Updated Successfully");
          setNotifi(true);
        } else {
          throw new Error(`Something went wrong ${status}`);
        }
      }
    } catch (err) {
      setMessage(err.message);
      setNotifi(true);
      console.error("Error:", err);
    }finally{
      setLoading(false)
    }
  };
  /////////////////////////////////////////////////////
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  //console.log(UserData);
  return (
    <>
      {Notifi && <AdditionalUses />}

      <Container>
        <Box sx={{ flexGrow: 1 }} component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography  variant="h6" >
                {postType === "Add" ? "Add User" : "Edit User"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="User Name"
                type="text"
                name="user_name"
                value={UserData.user_name || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Email Id"
                type="email"
                name="user_email"
                disabled={postType === "Edit" ? true : false}
                value={UserData.user_email || ""}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* <TextField
                fullWidth
                required
                label="Mobile no"
                type="number"
                name="mobile_no"
                value={UserData.mobile_no || ""}
                onChange={handleChange}
              /> */}
              <PhoneInput
                inputStyle={{
                  width: "100%",
                }}
                required
                // country={"in"}
                id="outlined-number"
                label="Mobile No"
                type="number"
                name="mobile_no"
                value={UserData?.mobile_no.toString()}
                onChange={(value) => {                  
                  setUserData({ ...UserData, mobile_no: value });
                }}
                inputProps={{
                  required: true,
                  
                }}
                
                // inputStyle={{
                //   width: '100%',
                //   height: '41px',
                //   // fontSize: '13px',
                //   // paddingLeft: '48px',
                //   borderRadius: '5px',
                // }}
                // // buttonStyle={{ borderRadius: '5px 0 0 5px' }}
                // // dropdownStyle={{ width: '300px' }}
              />
            </Grid>
            {postType !== "Edit" && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Create Password"
                  type="password"
                  name="password"
                  value={UserData.password || ""}
                  onChange={handleChange}
                />
              </Grid>
            )}
            {/* <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Gender"
                  disabled={postType === "Edit" ? true : false}
                  MenuProps={MenuProps}
                  value={UserData.gender}
                  name="gender"
                  onChange={(event, newValue) =>
                    setUserData({
                      ...UserData,
                      gender: event.target.value,
                    })
                  }
                >
                  <MenuItem value={""}>Select</MenuItem>
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12}>
              <Typography  variant="subtitle1">Access Controll</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={UserData.job_post_crud}
                      onChange={(e) => {
                        setUserData({
                          ...UserData,
                          job_post_crud: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Create Post/Edit/Close"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={UserData.resume_search}
                      onChange={(e) => {
                        setUserData({
                          ...UserData,
                          resume_search: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Resume Search"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={UserData.download_excel_format}
                      onChange={(e) => {
                        setUserData({
                          ...UserData,
                          download_excel_format: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Download Applicant Excel format"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} textAlign="end">
              <Button
                variant="contained"
                type="submit"
                sx={{background:gradientPrimary}}
                // color={postType === "Add" ? "primary" : "success"}
               
                startIcon={loading ? <CircularProgress size={24} color="inherit" />:postType === "Add" ? (
                  <GroupAddIcon />
                ) : (
                  <KeyboardDoubleArrowUpSharpIcon />
                )}
              >
                {postType === "Add" ? "Add User" : "Update User"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default AddEditUsers;
