import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { customTheme } from "../Theme";
import { Box } from '@mui/material';
import Consultancy from './Componends/Consultancy/Consultancy';
import PartnerDetails from './Componends/Consultancy/PartnerDetails';
import LobCompanies from './Componends/LOB/LOBCompanies/LobCompanies';
const Lob = lazy(() => import('./Componends/LOB/Lob'));
const DashBoard = lazy(() => import('./Componends/DashBoard/DashBoard'));
const JobPosting = lazy(() => import('./Componends/JobPosting/JobPosting'));
const AccessControl = lazy(() => import('./Componends/AccessControl/AccessControl'));
const Applicants = lazy(() => import('./Componends/Applicants/Applicants'));
const NewJobPostings = lazy(() => import('./Componends/JobPosting/NewJobPostings/NewJobPostings'));
const Settings = lazy(() => import('./Componends/Settings/Settings'));
const ResumeSearch = lazy(() => import('./Componends/ResumeSearch/ResumeSearch'));
const LoginForm = lazy(() => import('./Componends/LoginForm/LoginForm'));
const ParticularCandidates = lazy(() => import('./Componends/ParticularCandidate/ParticularCandidates'));
const RoleRoute = lazy(() => import('./Routing/RoleRoute'));
const PageNotFound = lazy(() => import('./PageNotFound/PageNotFound'));
const ProtectedRoute = lazy(() => import('./ProtectedRoute/ProtectedRoute'));
const Candidates = lazy(() => import('./Componends/Candidates/Candidates'));
const SearchFilter = lazy(() => import('./Componends/ResumeSearch/SearchFilter'));
const RegisterationStepOne = lazy(() => import('./RegisterForm/RegisterationStepOne'));
const RoutingLayout = lazy(() => import('./RoutingLayOut/RoutingLayout'));




const JobPortal = () => {
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Router>
          <Routes>
      
            <Route path="/Registration" element={<Suspense fallback={<div>Loading...</div>}><RegisterationStepOne /></Suspense>} />
            <Route path="/Login" element={<Suspense fallback={<div>Loading...</div>}><LoginForm /></Suspense>} />
            <Route
              path="/"
              element={
                <Suspense fallback={
                  <Box
                  sx={{
                    width: "100%",
                    height: "50vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                   <lottie-player
                  src="https://lottie.host/bb9ad14e-fd6f-4ad4-86f4-06e2efcf7e5c/v1Tw7wpjVh.json"
                  speed="0.5"
                  style={{ width: "80%", height: '80%',margin:"auto" }}
                  loop
                  autoplay
                  direction="1"
                  mode="normal"
                ></lottie-player>
                </Box>
               }>
                <ProtectedRoute>
                  <RoutingLayout />
                </ProtectedRoute>
              </Suspense>
              }
            >
              <Route index element={<DashBoard />} />
              <Route path="dashboard" element={<DashBoard />} />
              <Route path="candidates" element={<Candidates />} />
              <Route path="job posting/applicants" element={<Applicants />} />
              <Route path="resume search" element={<SearchFilter />} />
              <Route path="searchfilters" element={ <ResumeSearch />} />
              <Route path="job posting" element={<JobPosting />} />
              <Route path="Setting" element={<Settings />} />
              <Route path="job posting/Postform" element={<NewJobPostings />} />
              <Route path="getcandidate" element={<ParticularCandidates />} />
              <Route path="consultancy" element={<Consultancy />} />
              <Route path="partner" element={<PartnerDetails />} />
              <Route path="companydetails" element={<LobCompanies />} />
              <Route
                path="access control"
                element={
                  <Suspense fallback={<Box
                    sx={{
                      width: "100%",
                      height: "50vw",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                     <lottie-player
                    src="https://lottie.host/bb9ad14e-fd6f-4ad4-86f4-06e2efcf7e5c/v1Tw7wpjVh.json"
                    speed="0.5"
                    style={{ width: "80%", height: '80%',margin:"auto" }}
                    loop
                    autoplay
                    direction="1"
                    mode="normal"
                  ></lottie-player>
                  </Box>}>
                  <RoleRoute component={<AccessControl />} roles={["admin"]} />
                </Suspense>
                }
              />
              <Route
                path="lob"
                element={
                  <Suspense fallback={<Box
                    sx={{
                      width: "100%",
                      height: "50vw",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                     <lottie-player
                    src="https://lottie.host/bb9ad14e-fd6f-4ad4-86f4-06e2efcf7e5c/v1Tw7wpjVh.json"
                    speed="0.5"
                    style={{ width: "80%", height: '80%',margin:"auto" }}
                    loop
                    autoplay
                    direction="1"
                    mode="normal"
                  ></lottie-player>
                  </Box>}>
                  <RoleRoute component={<Lob />} roles={["admin"]} />
                </Suspense>
                }
              />
            </Route>
            <Route path="*" element={<Suspense fallback={<div>Loading...</div>}><PageNotFound /></Suspense>} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default JobPortal;
