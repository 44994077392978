import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { AllDataProvider } from "../../DataProvider/DataProvider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import { Tooltip } from "@mui/material";
import { gradientPrimary } from "../../../Theme";
const Dialogmodal = ({ children }) => {
  const { openDialogModal, setopenDialogModal, postType, setPostType } =
    AllDataProvider();
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const DialogModal = () => {
    setopenDialogModal(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={openDialogModal}
        // open={true}
        maxWidth="md"
        fullWidth
        scroll={"paper"}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
        
          <Tooltip title="Back" arrow>

          <Fab
            size="small"
            sx={{background:gradientPrimary,color:"white"}}
            variant="extended"
            aria-label="add"
            onClick={DialogModal}
          >
            <ArrowBackIcon />
          </Fab>
          </Tooltip>
      
        </DialogTitle>

        <DialogContent >
          <DialogContentText >{children}</DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Dialogmodal;
