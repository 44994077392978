import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AllDataProvider } from "../../DataProvider/DataProvider";
import Button from "@mui/material/Button";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardDoubleArrowUpSharpIcon from "@mui/icons-material/KeyboardDoubleArrowUpSharp";
import EditIcon from "@mui/icons-material/Edit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { AddPartner, AddUser, EditPartner, EditUser } from "../AxiosFetch/AllFetchMethod";
import { AdditionalUses } from "../AdditionalUses/AdditionalUses";
import { gradientPrimary } from "../../../Theme";
import { NumericFormat } from "react-number-format";
const materialUITextFieldProps = {
  id: "standard-basic",
  label: "Placement Price",
  fullWidth:true
};
const AddEditPartner = () => {
  const {
    Notifi,
    setNotifi,
    setMessage,
    setopenDialogModal,
    postType,
    userRecall,
    setUserRecall,
    particularpartner, setParticularPartner
  } = AllDataProvider();
  const [loading, setLoading] = useState(false);
  const [UserData, setUserData] = useState({
    partner_name: "",
    partner_email: "",
    institute_name:"",
    website_link:"",
    mobile_no: "",
    password: "", 
    partner_status:true,
    price:"",
  });

  useEffect(() => {
    if (postType === "Edit") {
      setUserData(particularpartner);
      //console.log(particularpartner);
    }
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserData((values) => ({ ...values, [name]: value }));
  };
  const handleMinAmount = ({ value }) => {
    setUserData((values) => ({ ...values, price: value }));
  };
  /////////////////////////////////////////////////////
  let PostVal = {
    ...UserData,
  };
  //console.log(PostVal);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      if (postType === "Add") {
        let { status, data } = await AddPartner(PostVal);
        if (status === 201) {
          setUserRecall(!userRecall);
          setopenDialogModal(false);
          setMessage(data.message);
          setNotifi(true);
        } else {
          throw new Error(data.status);
        }
      } else {
        let { status, data } = await EditPartner(PostVal);
        if (status === 200) {
          setUserRecall(!userRecall);
          setopenDialogModal(false);
          setMessage("Updated Successfully");
          setNotifi(true);
        } else {
          throw new Error(`Something went wrong ${status}`);
        }
      }
    } catch (err) {
      setMessage(err.message);
      setNotifi(true);
      console.error("Error:", err);
    }finally{
      setLoading(false)
    }
  };
  /////////////////////////////////////////////////////
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  //console.log(UserData);
  return (
    <>
      {Notifi && <AdditionalUses />}

      <Container>
        <Box sx={{ flexGrow: 1 }} component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography  variant="h6" >
                {postType === "Add" ? "Add Partner" : "Edit Partner"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Partner Name"
                type="text"
                name="partner_name"
                value={UserData.partner_name || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Institute Name"
                type="text"
                name="institute_name"
                value={UserData.institute_name || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Website Link"
                type="url"
                name="website_link"
                value={UserData.website_link || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Email Id"
                type="email"
                name="partner_email"
                disabled={postType === "Edit" ? true : false}
                value={UserData.partner_email || ""}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* <TextField
                fullWidth
                required
                label="Mobile no"
                type="number"
                name="mobile_no"
                value={UserData.mobile_no || ""}
                onChange={handleChange}
              /> */}
              <PhoneInput
                inputStyle={{
                  width: "100%",
                }}
                required
                country={"in"}
                id="outlined-number"
                label="Mobile No"
                type="number"
                name="mobile_no"
                value={UserData?.mobile_no.toString()}
                onChange={(value) => {                  
                  setUserData({ ...UserData, mobile_no: value });
                }}
                inputProps={{
                  required: true,
                  
                }}
                
                // inputStyle={{
                //   width: '100%',
                //   height: '41px',
                //   // fontSize: '13px',
                //   // paddingLeft: '48px',
                //   borderRadius: '5px',
                // }}
                // // buttonStyle={{ borderRadius: '5px 0 0 5px' }}
                // // dropdownStyle={{ width: '300px' }}
              />
            </Grid>
   
            <Grid item xs={12} md={6}>
            <NumericFormat
                    customInput={TextField}
                    thousandSeparator
                    prefix={"₹"}
                    onValueChange={handleMinAmount}
                    {...materialUITextFieldProps}
                    value={UserData.price || ""}
                  />
            </Grid>
            {postType !== "Edit" && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Create Password"
                  type="password"
                  name="password"
                  value={UserData.password || ""}
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={UserData.partner_status}
                    onChange={(e) => {
                      setUserData({
                        ...UserData,
                        partner_status: e.target.checked,
                      });
                    }}
                  />
                }
                label="Status"
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Gender"
                  disabled={postType === "Edit" ? true : false}
                  MenuProps={MenuProps}
                  value={UserData.gender}
                  name="gender"
                  onChange={(event, newValue) =>
                    setUserData({
                      ...UserData,
                      gender: event.target.value,
                    })
                  }
                >
                  <MenuItem value={""}>Select</MenuItem>
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            
            <Grid item xs={12} textAlign="end">
              <Button
                variant="contained"
                type="submit"
                sx={{background:gradientPrimary}}
                // color={postType === "Add" ? "primary" : "success"}
               
                startIcon={loading ? <CircularProgress size={24} color="inherit" />:postType === "Add" ? (
                  <GroupAddIcon />
                ) : (
                  <KeyboardDoubleArrowUpSharpIcon />
                )}
              >
                {postType === "Add" ? "Add Partner" : "Update Partner"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default AddEditPartner;
