import React, { createContext, useContext,  useEffect,  useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import { GetAllCountry } from "../Componends/AxiosFetch/AllFetchMethod";
import { styled,Typography} from "@mui/material";
const ContextProvider = createContext(null);
export function DataProvider({ children }) {
/////////////////////////////////////////////////////////////
const [Login,setLogin] = useState(false)
/////////////////////////////////////////////////////
const [SwipeDra, setSwipeDra] = React.useState({
  right: false,
});

const toggleDrawer = (anchor, open) => (event) => {
  if (
    event &&
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) {
    return;
  }

  setSwipeDra({ ...SwipeDra, [anchor]: open });
};

//////////////////////////////////////////////////////////
const [selectCompany, setSelectComapny] = useState({
  val: "",
  index: {},
});

const [Postname,setPostName] = useState("")
const [comments,setComments] = useState("");
const [NoShow,setNoShow] = useState(false);
 const [candidatestatus,setcandidatestatus] = useState({
   type:"",
   id:""
 })
  const [roleLogin,setRolelogin] = useState(JSON.parse(localStorage.getItem("accesscontrol"))) 
  const [activeStep, setActiveStep] = React.useState(0);
  const [country,setCountry] = useState([])
  const [State,setState] = useState([])
  const [Notifi, setNotifi] = React.useState(false);
  const [message, setMessage] = useState("");
  const [openEmailModule, setOpenEmailModule] = React.useState(false);
  const [ openDraftModal, setopenDraftModal] = React.useState(false);
  const [postType, setPostType] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [openAlertModal, setopenAlertModal] = React.useState(false);
  const [openDialogModal, setopenDialogModal] = React.useState(false);
  const [openAudiomodal, setopenAudiomodal] = React.useState(false);
  const [Applicantvalue, setApplicantValue] = React.useState(0);
  const [jobPostList, setJobPostList] = useState([]);
  const [LobjobPostList, setLobJobPostList] = useState([]);
  const [postDataRecall, setpostDataRecall] = useState(true);
  const [ApplicantDataRecall, setApplicantDataRecall] = useState(true);
  const [FilterDataRecall, setFilterDataRecall] = useState(false);
  const [userRecall, setUserRecall] = useState(true);
  const [AllApplicant, setAllApplicant] = useState([]);
  const [NewApplicant, setNewApplicant] = useState([]);
  const [inProgressApplicant, setInProgressApplicant] = useState([]);
  const [HiredList, setHiredList] = useState([]);
  const [RejectedList, setRejectedList] = useState([]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [closeList, setCloseList] = useState([]);
  const [draftList, setDraftList] = useState([]);
  const [ParticularPostData, setParticularPostData] = useState({});
  const [downloadexcel, setDownloadexcel] = useState([]);
  const [postID, setPostId] = useState("");
  const [Hirepage, setHirePage] = React.useState(1);
  const [Newpage, setNewPage] = React.useState(1);
  const [Allpage, setAllPage] = React.useState(1);
  const [inprogresspage, setInProgressPage] = React.useState(1);
  const [Rejectpage, setRejectPage] = React.useState(1);
  const [AllPageCount,setAllPageCount] = React.useState(1);
  const [countPerPage,setCountPerpage] = useState("all");
  const [countFilterPerPage, setcountFilterPerPage] = useState("all");
  const [sortValue,setSortValue] = useState(true)
  const [ApplicantFilter,setApplicantFilter] = useState({salary:0,
    match_percentage:false,
    experience:0,
    city:[],
    key_skills:[],
    notice_period:[]
  })
  const [ResumeFilter, setResumeFilter] = useState({
    salary: 0,
    experience: 0,
    city: "",
    state: "",
    post_country: "",
    key_skills: [],
    new_Applicant:false,
    job_title:[],
    company_name:[],
    scheduled_status_payload:"",
    resume_update : '',
    contacted:"",
    gender:""
  });
  const [Audioplay,setAudiPlay] = useState({profile_img:"",audio:""})
  const [AllpageSearch, setAllpageSearch] = useState(1);
  const [AllpageSearchCount, setAllpageSearchCount] = useState(1);
  const [citydata, setcitydata] = useState([]);
  const [SearchApplicant  , setSearchApplicant  ] = useState([]);
  const [KeySkills, setKeySkills] = React.useState([]);
  const [ListQuestions,setListQuestions] = useState({})
  const [particularUser, setParticularUser] = useState({
    user_name: "",
    user_email: "",
    mobile_no: 0,
    password: "",
    gender: "",
    job_post_crud: false,
    resume_search: false,
    download_excel_format: false,
  });
  const [particularCompany, setParticularCompany] = useState({
    user_name: "",
    user_email: "",
    mobile_no: 0,
    
  });
  const [particularpartner, setParticularPartner] = useState({
    partner_name: "",
    partner_email: "",
    mobile_no: 0,
    password: "",   
  });
  let ClickPostFunction = () => {
    setPostType("Post");
    setOpenModal(true);
  };
  const handleNext = () => {    
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  //////////////////////////////////////////////////////////////////////////////////////
  const [showQuestion, setShowQuestion] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
  });
  const [certificate, setCertificate] = useState([
    {
      id: 1,
      required: false,
      Showdata: "",
      question: "Do you have a Valid ?",
      type: "text",
    },
  ]);
  const [language, setlanguage] = useState([
    {
      id: 1,
      required: false,
      Showdata: "",
      question: "Are you proficient in speaking ?",
      type: "options",
    },
  ]);
  const [experience, setExperience] = useState([
    {
      id: 1,
      required: false,
      Showdata: "",
      question: "",
      type: "number",
    },
  ]);
  const [ShiftTime, setShiftTime] = useState({
    required: false,
    Showdata: [],
    question: "Are you comfortable with this shift ?",
    type: "choose",
    dropdown: ["DayShift", "NightShift", "Flexible", "Contract"],
  });
  const [relocate, setRelocate] = useState({
    required: false,
    Showdata: [],
    question: "Are you open to the possibility of relocating to ?",
    type: "options",
  });
  const [minEducation, setminEducation] = useState({
    required: false,
    Showdata: "",
    type: "choose",
    question: "What is your highest educational qualification ?",
    dropdown: [
      "Secondary(10th)",
      "Higher Secondary(12th)",
      "Diploma",
      "Bachelors",
      "Masters",
      "Doctorate",
    ],
  });
  const [CustomQuestions, setCustomQuestions] = useState([
    {
      id: 1,
      required: false,
      Showdata: "",
      question: "",
      type: "text",
    },
  ]);

  const ResetQuestions = () =>{
    //console.log("Reset Called")
    setCertificate([
      {
        id: 1,
        required: false,
        Showdata: "",
        question: "Do you have a Valid ?",
        type: "text",
      },
    ]);
    setlanguage([
      {
        id: 1,
        required: false,
        Showdata: "",
        question: "Are you proficient in speaking ?",
        type: "options",
      },
    ]);
    setExperience([
      {
        id: 1,
        required: false,
        Showdata: "",
        question: "",
        type: "number",
      },
    ]);
    setShiftTime({
      required: false,
      Showdata: [],
      question: "Are you comfortable with this shift ?",
      type: "choose",
      dropdown: ["DayShift", "NightShift", "Flexible", "Contract"],
    });
    setRelocate({
      required: false,
      Showdata: [],
      question: "Are you open to the possibility of relocating to ?",
      type: "options",
    });
    setminEducation({
      required: false,
      Showdata: "",
      type: "choose",
      question: "What is your highest educational qualification ?",
      dropdown: [
        "Secondary(10th)",
        "Higher Secondary(12th)",
        "Diploma",
        "Bachelors",
        "Masters",
        "Doctorate",
      ],
    });
    setCustomQuestions([
      {
        id: 1,
        required: false,
        Showdata: "",
        question: "",
        type: "text",
      },
    ]);
    setListQuestions({});
    setShowQuestion({
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      seven: false,
    });
  }
  ////////////////////////////////////////////////////////////////////////////////////
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  let CustomNoRowsOverlay = () => {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Typography variant="h6">No Data</Typography>
      </StyledGridOverlay>
    );
  };
  //////////////////////////////////////LogOut////////////////////////////////////////
  const LogOut = () =>{
    localStorage.clear();
    window.location.replace("/Login")
  }
 
  useEffect(() => {
    (async () => {
      try {
        const { data, status } = await GetAllCountry();
        setCountry(data)
      } catch (err) {
        setMessage(err.message);
        setNotifi(true);
        //console.log(err);
      }
    })();
  }, []);
  ////////////////////////////////////////////////////////////////////////////
 const [Locations,setLocations] = useState([])
 const [ResumeURL,setResumeURL] = useState("")
  return (
    <>
      <ContextProvider.Provider
        value={{
          CustomNoRowsOverlay,
          Notifi,
          setNotifi,
          openEmailModule,
          setOpenEmailModule,
          postType,
          setPostType,
          openModal,
          setOpenModal,
          Applicantvalue,
          setApplicantValue,
          ClickPostFunction,
          openAlertModal, openDraftModal, setopenDraftModal,
          setopenAlertModal,
          openDialogModal,
          setopenDialogModal,
          email,
          setEmail,
          message,
          setMessage,NoShow,setNoShow,
          jobPostList,countFilterPerPage, setcountFilterPerPage,
          setJobPostList,LobjobPostList, setLobJobPostList,
          postDataRecall,
          setpostDataRecall,
          loading,
          setLoading,
          closeList,
          setCloseList,
          Locations,
          setLocations,
          ParticularPostData,
          setParticularPostData,
          draftList,
          setDraftList,
          userRecall,selectCompany, setSelectComapny,
          setUserRecall,Newpage, setNewPage,particularpartner, setParticularPartner,
          particularUser,
          setParticularUser,particularCompany, setParticularCompany,
          ApplicantDataRecall,
          setApplicantDataRecall,FilterDataRecall, setFilterDataRecall,
          AllApplicant,
          setAllApplicant,SearchApplicant  , setSearchApplicant  ,
          inProgressApplicant,
          setInProgressApplicant,
          HiredList,NewApplicant, setNewApplicant,
          setHiredList,AllpageSearch, setAllpageSearch,AllpageSearchCount, setAllpageSearchCount,
          RejectedList,
          setRejectedList,
          downloadexcel,
          setDownloadexcel,
          postID,
          setPostId,
          Hirepage,
          setHirePage,Postname,setPostName,
          Allpage,SwipeDra, setSwipeDra,toggleDrawer,
          setAllPage,NewApplicant, setNewApplicant,
          inprogresspage,Audioplay,setAudiPlay,openAudiomodal, setopenAudiomodal,
          setInProgressPage,State,setState,
          Rejectpage,certificate, setCertificate,
          setRejectPage,CustomQuestions, setCustomQuestions,relocate, setRelocate,ShiftTime, setShiftTime,language, setlanguage,
          AllPageCount,setAllPageCount,countPerPage,setCountPerpage,experience, setExperience
          ,Login,setLogin,minEducation, setminEducation,ResumeFilter, setResumeFilter,
          ApplicantFilter,setApplicantFilter,sortValue,setSortValue,Tooltip
          ,roleLogin,setRolelogin,activeStep, setActiveStep,handleNext,LogOut,ResumeURL,setResumeURL,
          KeySkills, setKeySkills,ListQuestions,setListQuestions,showQuestion, setShowQuestion,ResetQuestions,
          country,setCountry,citydata, setcitydata,comments,setComments,candidatestatus,setcandidatestatus
        }}
      >
        {children}
      </ContextProvider.Provider>
    </>
  );
}
export function AllDataProvider() {
  let {
    CustomNoRowsOverlay,
    Notifi,
    setNotifi,
    openEmailModule,
    setOpenEmailModule,
    postType,
    setPostType,
    openModal,NoShow,setNoShow,
    setOpenModal,Newpage, setNewPage,
    Applicantvalue,ResumeFilter, setResumeFilter,
    setApplicantValue,countFilterPerPage, setcountFilterPerPage,
    ClickPostFunction,AllpageSearch, setAllpageSearch,AllpageSearchCount, setAllpageSearchCount,
    openAlertModal,selectCompany, setSelectComapny, openDraftModal, setopenDraftModal,
    setopenAlertModal,Audioplay,setAudiPlay,
    openDialogModal,Postname,setPostName,
    setopenDialogModal,
    email,KeySkills, setKeySkills,SearchApplicant  , setSearchApplicant  ,
    setEmail,SwipeDra, setSwipeDra,toggleDrawer,
    message,NewApplicant, setNewApplicant,
    setMessage,
    jobPostList,LobjobPostList, setLobJobPostList,
    setJobPostList,
    postDataRecall,
    setpostDataRecall,
    loading,
    setLoading,
    closeList,
    setCloseList,
    Locations,
    setLocations,
    ParticularPostData,
    setParticularPostData,
    draftList,
    setDraftList,
    userRecall,
    setUserRecall,particularpartner, setParticularPartner,
    particularUser,
    setParticularUser,particularCompany, setParticularCompany,
    ApplicantDataRecall,
    setApplicantDataRecall,FilterDataRecall, setFilterDataRecall,
    AllApplicant,
    setAllApplicant,
    inProgressApplicant,
    setInProgressApplicant,
    HiredList,
    setHiredList,
    RejectedList,
    setRejectedList,
    downloadexcel,
    setDownloadexcel,
    postID,
    setPostId,
    Hirepage,
    setHirePage,
    Allpage,
    setAllPage,
    inprogresspage,
    setInProgressPage,State,setState,
    Rejectpage,language, setlanguage,country,setCountry,openAudiomodal, setopenAudiomodal,
    setRejectPage,CustomQuestions, setCustomQuestions,relocate, setRelocate,
    AllPageCount,setAllPageCount,countPerPage,setCountPerpage,ShiftTime, setShiftTime
    ,Login,setLogin,ListQuestions,setListQuestions,minEducation, setminEducation,experience, setExperience,
    ApplicantFilter,setApplicantFilter,sortValue,setSortValue,Tooltip,certificate, setCertificate
    ,roleLogin,setRolelogin,activeStep, setActiveStep, handleNext, LogOut, showQuestion, setShowQuestion,ResetQuestions
    ,citydata, setcitydata,comments,setComments,candidatestatus,setcandidatestatus,ResumeURL,setResumeURL,
  } = useContext(ContextProvider);
  return {
    CustomNoRowsOverlay,
    Notifi,
    setNotifi,
    openEmailModule,
    setOpenEmailModule,selectCompany, setSelectComapny,
    postType,Newpage, setNewPage,
    setPostType,ResumeFilter, setResumeFilter,
    openModal,KeySkills, setKeySkills,
    setOpenModal,
    Applicantvalue,Postname,setPostName,
    setApplicantValue,
    ClickPostFunction,
    openAlertModal, openDraftModal, setopenDraftModal,
    setopenAlertModal,
    openDialogModal,
    setopenDialogModal,
    email,Audioplay,setAudiPlay,
    setEmail,NewApplicant, setNewApplicant,
    message,NoShow,setNoShow,
    setMessage,countFilterPerPage, setcountFilterPerPage,
    jobPostList,
    setJobPostList,LobjobPostList, setLobJobPostList,
    postDataRecall,
    setpostDataRecall,
    loading,
    setLoading,
    closeList,
    setCloseList,
    Locations,
    setLocations,
    ParticularPostData,
    setParticularPostData,
    draftList,
    setDraftList,
    userRecall,
    setUserRecall,particularpartner, setParticularPartner,
    particularUser,
    setParticularUser,particularCompany, setParticularCompany,
    ApplicantDataRecall,SwipeDra, setSwipeDra,toggleDrawer,
    setApplicantDataRecall,FilterDataRecall, setFilterDataRecall,
    AllApplicant,
    setAllApplicant,SearchApplicant  , setSearchApplicant  ,
    inProgressApplicant,
    setInProgressApplicant,
    HiredList,
    setHiredList,
    RejectedList,
    setRejectedList,
    downloadexcel,
    setDownloadexcel,
    postID,
    setPostId,
    Hirepage,
    setHirePage,
    Allpage,AllpageSearch, setAllpageSearch,AllpageSearchCount, setAllpageSearchCount,
    setAllPage,State,setState,
    inprogresspage,country,setCountry,
    setInProgressPage,language, setlanguage,
    Rejectpage,ShiftTime, setShiftTime,
    setRejectPage,ListQuestions,setListQuestions,
    AllPageCount,setAllPageCount,countPerPage,setCountPerpage
    ,Login,setLogin,roleLogin,setRolelogin,relocate, setRelocate,openAudiomodal, setopenAudiomodal,
    ApplicantFilter,setApplicantFilter,sortValue,setSortValue,Tooltip,experience, setExperience
    ,activeStep, setActiveStep, handleNext, LogOut, showQuestion, setShowQuestion,ResetQuestions,
    CustomQuestions, setCustomQuestions,minEducation, setminEducation,certificate, setCertificate,
    citydata, setcitydata,comments,setComments,candidatestatus,setcandidatestatus,ResumeURL,setResumeURL,
  };
}
