import "./App.css";
import JobPortal from "./JobPortal/JobPortal";
import CssBaseline from "@mui/material/CssBaseline";
import { DataProvider } from "./JobPortal/DataProvider/DataProvider";
import Aos from "aos";
import "aos/dist/aos.css" 
import { useEffect } from "react";
function App() {
  useEffect(()=>{
   Aos.init({
    duration: 1000,
   })
  },[])
  return (
    <>
    <DataProvider>
      <CssBaseline />

      <JobPortal />
      </DataProvider>
    </>
  );
}

export default App;
