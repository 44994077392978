import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  DataGrid,
  GridToolbar,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
  gridClasses,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  captionStyle,
  CardDatas,
  gradientCandi,
  gradientPrimary,
  gradientrevenue,
  RadiusProp,
  ShadowProp,
  StyledCardBox,
  StyledCardData,
  StyledCardTypo,
} from "../../../Theme";
import { useLocation, useNavigate } from "react-router-dom";
import { AllDataProvider } from "../../DataProvider/DataProvider";
import { GetPartnerDetails } from "../AxiosFetch/AllFetchMethod";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PartnerDetails = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const { state: { id = undefined } = {} } = useLocation();
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const {
    openDialogModal,
    setopenDialogModal,
    Notifi,
    setNotifi,
    setMessage,
    CustomNoRowsOverlay,
  } = AllDataProvider();
  // const getRowId = (row) => row.user_id;
  const [UserList, setUserList] = useState([]);
  const [cardData, setcardData] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    Postedby: true,
  });
  const [particularUser, setParticularUser] = useState({});
  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    (async () => {
      try {
        let { status, data } = await GetPartnerDetails(id);
        if (status === 200) {
          setParticularUser(data.partner_details);
          setUserList(data.candidate_details);
          setcardData(data.card_details);
        } else {
          throw new Error(data.status);
        }
      } catch (err) {
        setMessage(err.message);
        setNotifi(true);
        //console.log(err);
      }
    })();
  }, [openDialogModal]);

  const GetParticularApplicant = (applicant_id) => {
    const stateData = {
      applicant_id,
      type: "resumesearch",
    };

    const url = `/getcandidate?applicant_id=${applicant_id}&type=resumesearch`;

    const newTab = window.open(url, "_blank");

    if (!newTab) {
      navigate(url, { state: stateData });
    }
  };
  let columns = [
    {
      id: 1,
      field: "candidate_name",
      headerName: "Candidate Name ",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: ({ row }) => (
        <Tooltip title="View Candidate" arrow>
          <Typography
            sx={{
              cursor: "pointer",
              color: "primary.light",
              fontWeight: "500",
              letterSpacing: "0.5px",
              textDecoration: "underline",
            }}
            onClick={() => GetParticularApplicant(row.candidate_id)}
            variant="body2"
          >
            {row.candidate_name}
          </Typography>
        </Tooltip>
      ),
    },

    {
      id: 3,
      field: "candidate_mobile",
      headerName: "Mobile No",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {row.candidate_mobile}
        </Typography>
      ),
    },
    {
      id: 4,
      field: "candidate_email",
      headerName: "Email Id",
      headerClassName: "super-app-theme--header",
      width: 250,
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {row.candidate_email}
        </Typography>
      ),
    },
    // {
    //   id: 5,
    //   field: "candidate_register_date",
    //   headerName: "Registered on",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,  renderCell: ({ row }) => (
    //     <Typography variant="caption" sx={captionStyle}>{row.candidate_register_date}</Typography>
    //   ),
    // },
    {
      id: 5,
      field: "purchase_date",
      headerName: "Purchased On",
      headerClassName: "super-app-theme--header",
      width: 100,
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {row.purchase_date}
        </Typography>
      ),
    },
    {
      id: 5,
      field: "purchase_amount",
      headerName: "Payed",
      headerClassName: "super-app-theme--header",
      width: 100,
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {Number(row.purchase_amount).toLocaleString()}
        </Typography>
      ),
    },
  ];
  const formatValue = (num) => {
    if (num >= 100000) return `${num / 100000}L`;
    if (num >= 1000) return `${num / 1000}K`;
    return num;
  };
  return (
    <>
      <Box
        sx={{
          maxWidth: "100vw",
          bgcolor: "background.paper",
          typography: "body1",
        }}
      >
         <Fab
              size="small"
              sx={{ background: gradientPrimary, color: "white" }}
              variant="extended"
              aria-label="add"
              onClick={() => window.history.back()}
            >
              <ArrowBackIcon />
            </Fab>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
          >
            <Tab label="Partner Details" {...a11yProps(0)} />
            <Tab label="Candidates Purchased Details" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
         <>         
          <Paper sx={{ flexGrow: 1, padding: "2rem" }} elevation={0}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box sx={{ width: "200px", height: "auto" }}>
                  <img
                    src={particularUser?.institute_logo}
                    alt="logo"
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <StyledCardTypo sx={{ fontSize: "1rem" }}>
                  Institute Name
                </StyledCardTypo>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  {particularUser?.institute_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <StyledCardTypo sx={{ fontSize: "1rem" }}>Name</StyledCardTypo>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  {particularUser?.partner_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <StyledCardTypo sx={{ fontSize: "1rem" }}>
                  PartnerId
                </StyledCardTypo>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  {particularUser?.partner_id}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <StyledCardTypo sx={{ fontSize: "1rem" }}>
                  Email Id
                </StyledCardTypo>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ textTransform: "lowercase" }}
                >
                  {particularUser?.partner_email}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <StyledCardTypo sx={{ fontSize: "1rem" }}>
                  Website
                </StyledCardTypo>
              </Grid>
              <Grid item xs={6}>
                <a href={particularUser?.website_link} target="_blank">
                  {particularUser?.website_link}
                </a>
              </Grid>

              <Grid item xs={6}>
                <StyledCardTypo sx={{ fontSize: "1rem" }}>
                  Mobile No
                </StyledCardTypo>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  {particularUser?.mobile_no}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  fullWidth
                  id="outlined-multiline-static"
                  label="Address"
                  multiline
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={particularUser?.address?.trim()}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  fullWidth
                  id="outlined-multiline-static"
                  label="Our Services"
                  multiline
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={particularUser?.our_services?.trim()}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  fullWidth
                  id="outlined-multiline-static"
                  label="About"
                  multiline
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={particularUser?.about?.trim()}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  fullWidth
                  id="outlined-multiline-static"
                  label="Terms and Conditions & Policy"
                  multiline
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={particularUser?.conditions?.trim()}
                />
              </Grid>
            </Grid>
          </Paper>
         </>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
       <Stack spacing={2}>
       <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              alignItems: "center",
              gap: 4,
            }}
          >
            <StyledCardData>
              <CardDatas
                sx={{
                  background: gradientCandi,
                }}
              >
                <Groups2RoundedIcon fontSize="medium" />
              </CardDatas>
              <StyledCardBox>
                <Stack alignItems="end">
                  <Typography variant="h6">
                    {cardData?.candidates_count}
                  </Typography>
                  <StyledCardTypo variant="body1" color="text.secondary">
                    Candidates
                  </StyledCardTypo>
                </Stack>
              </StyledCardBox>
            </StyledCardData>
            <StyledCardData>
              <CardDatas
                sx={{
                  background: gradientrevenue,
                }}
              >
                <AccountBalanceWalletRoundedIcon fontSize="medium" />
              </CardDatas>
              <StyledCardBox>
                <Stack alignItems="end">
                  <Typography variant="h6">
                    {cardData?.Amount
                      ? formatValue(Number(cardData?.Amount))
                      : 0}
                  </Typography>
                  <StyledCardTypo variant="body1" color="text.secondary">
                    Purchased
                  </StyledCardTypo>
                </Stack>
              </StyledCardBox>
            </StyledCardData>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                height: "550px",
                overflowX: "auto",
                width: isMdScreen ? "90%" : "100vw",
                boxShadow: ShadowProp,
                borderRadius: RadiusProp,
                padding: "1rem",
              }}
            >
              <DataGrid
                //   getRowId={getRowId}
                disableRowSelectionOnClick
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                checkboxSelection
                pageSizeOptions={[5, 10, 25]}
                columns={columns.map((column) => ({
                  ...column,
                  sortable: false,
                }))}
                rows={UserList}
                slots={{
                  noRowsOverlay: () => CustomNoRowsOverlay(),
                  toolbar: () => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <GridToolbar />
                    </Box>
                  ),
                }}
                getRowHeight={() => "auto"}
                sx={{
                  [`& .${gridClasses.cell}`]: {
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                  },
                  border: "none",
                  // '& .MuiDataGrid-cell:hover': {
                  //   color: 'primary.main',
                  // },
                }}
              />
            </Box>
         </Box> 
       </Stack>
        </CustomTabPanel>
      </Box>
     
        {/* <Stack spacing={2}>
          <Box
            sx={{
              marginBottom: "15px",
              display: "flex",
              gap: 1,
              alignContent: "center",
            }}
          >
            <Fab
              size="small"
              sx={{ background: gradientPrimary, color: "white" }}
              variant="extended"
              aria-label="add"
              onClick={() => window.history.back()}
            >
              <ArrowBackIcon />
            </Fab>
            
          </Box>
        

        
        </Stack> */}
      
    </>
  );
};

export default PartnerDetails;
