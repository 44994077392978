import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";

// axios.defaults.baseURL = "http://3.108.148.125:9000";
// axios.defaults.baseURL = "http://192.168.29.12:8000";
axios.defaults.baseURL = "https://employee.seltam.co.in";
// axios.defaults.timeout = 5000;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.interceptors.request.use(async(config) => {
  let token =await localStorage.getItem("AccessToken")
  //console.log(config.headers);
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization:
        `Bearer ${token}`,
    },
  };
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);


