import axios from "axios";
let source = null;
export const RecruiterLogin = async (email) => {
  try {
    const response = await axios.post("api/company_otp_login", { email });
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const RegisterResendOTP = async (email) => {
  try {
    const response = await axios.patch("api/resend_otp", { email });
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const UserProfileLogin = async (data) => {
  try {
    const response = await axios.post("api/hr_login", data);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const CompanyRegisterForm = async (data) => {
  try {
    const response = await axios.post("api/company_register", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const CompanyRegisterFinalForm = async (data) => {
  try {
    const response = await axios.post("api/company_details", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const CompanyRegisterotp = async (email, otp) => {
  try {
    const response = await axios.post("api/verify_otp", {
      email,
      otp,
    });
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};

export const RecruiterOTPLogin = async (email, otp) => {
  try {
    const response = await axios.post("api/otp_login_verification", {
      email,
      otp,
    });
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const ForgotPasswordOTP = async (email) => {
  try {
    const response = await axios.post("api/hr_forgot_password", {
      email,
    });
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const NewPasswordOTP = async (data) => {
  try {
    const response = await axios.post("api/hr_forgot_password_otp", data);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const NewPasswordConfrim = async (email, password) => {
  try {
    const response = await axios.post("api/hr_create_new_password", {
      email,
      password,
    });
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetAAllApplicantList = async (URL) => {
  try {
    const response = await axios.get(`/api/${URL}`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetAllCountry = async () => {
  var config = {
    method: "get",
    url: "https://api.countrystatecity.in/v1/countries",
    headers: {
      "X-CSCAPI-KEY":
        "RWxveU1QMHpXU3ZhUlo2cVRWUHMwSER5WWJ0czBzTmpMeGZoSFdwTA==",
    },
  };
  try {
    const response = await axios(config);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetResumeAllCountry = async () => {
  try {
    const response = await axios.get(`/api/get_country_list`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetResumestate = async (country) => {
  try {
    const response = await axios.get(`/api/get_state?country_name=${country}`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetResumeCity = async (country, state) => {
  try {
    const response = await axios.get(
      `/api/get_city?country_name=${country}&state_name=${state}`
    );
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetAllState = async (state) => {
  var config = {
    method: "get",
    url: `https://api.countrystatecity.in/v1/countries/${state}/states`,
    headers: {
      "X-CSCAPI-KEY":
        "RWxveU1QMHpXU3ZhUlo2cVRWUHMwSER5WWJ0czBzTmpMeGZoSFdwTA==",
    },
  };
  try {
    const response = await axios(config);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetAllStatecity = async (state, cities) => {
  var config = {
    method: "get",
    url: `https://api.countrystatecity.in/v1/countries/${state}/states/${cities}/cities`,
    headers: {
      "X-CSCAPI-KEY":
        "RWxveU1QMHpXU3ZhUlo2cVRWUHMwSER5WWJ0czBzTmpMeGZoSFdwTA==",
    },
  };
  try {
    const response = await axios(config);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
///////////////////////////////Resume serach/////////////////////////////
export const GetResumeFilterdata = async (querysearch) => {
  try {
    const response = await axios.get(`/api/resume_search?${querysearch}`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetResumeStatusdata = async (data) => {
  try {
    const response = await axios.post(`/api/scheduled_details`, data);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const SendCommentStatus = async (data) => {
  try {
    const response = await axios.post(`/api/comments_details`, data);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetResumeSearchKeySkills = async () => {
  try {
    const response = await axios.get(`/api/get_skill`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetResumeSearchCompany = async () => {
  try {
    const response = await axios.get(`/api/company_name`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetAAllQueriList = async () => {
  try {
    const response = await axios.get(`/api/company_list_of_quries`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetResumeSearchJobTitle = async () => {
  try {
    const response = await axios.get(`/api/Job_position`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};

////////////////////////////////////////////////////////////////////

export const GetAAllPostList = async (URL) => {
  if (source) {
    source.cancel();
  }
  source = axios.CancelToken.source();
  try {
    const response = await axios.get(`/api/${URL}`, {
      cancelToken: source.token,
    });
    //console.log(response);
    return response;
  } catch (err) {
    if (axios.isCancel(err)) {
      //console.log("Request canceled:", err); // Log if request is canceled
      return {
        data: [],
        status: 200,
      };
    }
    return err.response;
  }
};
export const PassCloseJob = async (id) => {
  try {
    const response = await axios.patch("api/post_close_job", {
      job_post_id: id,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};
export const SendQueriestatus = async (data) => {
  try {
    const response = await axios.post("api/add_quries", {
      qurie: data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const PassResumeJob = async (id, data) => {
  try {
    const response = await axios.patch("api/post_pass_and_resume", {
      job_post_id: id,
      post_status: data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const GetADominetList = async () => {
  try {
    const response = await axios.get(`api/get_skills`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
export const GetAKeySkillList = async (data) => {
  try {
    const response = await axios.get(`api/key_skills_get?job_title=${data}`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
//////////////////////////////////////////////////////////////////////////
export const GetAllApplicantLocation = async (id) => {
  try {
    const response = await axios.get(`api/get_responce_location?id=${id}`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
export const GetNotifiCount = async () => {
  try {
    const response = await axios.get(`api/total_new_apply_count`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
export const GetCountData = async (id) => {
  try {
    const response = await axios.get(
      `api/particular_job_post_count?post_id=${id}`
    );
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const PostJobData = async (data) => {
  try {
    const response = await axios.post("api/add_job_post", data);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const PostDraft = async (data) => {
  //console.log(data);
  try {
    const response = await axios.post("api/add_draft_post", data);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const CheckTitle = async (data) => {
  //console.log(data);
  try {
    const response = await axios.post("api/job_title_filter", {
      title_name: data,
    });
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const EditJobData = async (data) => {
  //console.log(data);
  try {
    const response = await axios.patch("api/edit_job_post", data);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const RepostJobData = async (data) => {
  //console.log(data);
  try {
    const response = await axios.patch("api/job_repost", {
      post_id: data,
    });
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const PostDraftJobData = async (data) => {
  //console.log(data);
  try {
    const response = await axios.patch("api/draft_post", {
      post_id: data,
    });
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const DeleteDraftJobData = async (data) => {
  //console.log(data);
  try {
    const response = await axios.patch("api/draft_delete_post", {
      post_id: data,
    });
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};

export const GetTemplates = async () => {
  try {
    const response = await axios.get(`api/get_template`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
export const GetDashboarddata = async (URL) => {
  try {
    const response = await axios.get(URL);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
export const GetCandidateList = async (URL) => {
  try {
    const response = await axios.get(URL);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const ApplicantReviewed = async (id, status, postId) => {
  try {
    const response = await axios.post("api/candidate_reviewed_status", {
      reviewed_status: status,
      candidate_id: id,
      post_id: postId,
    });
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const ChangeCandidatePost = async (data) => {
  try {
    const response = await axios.post("api/change_apply_application", data);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const NotifyCandi = async (id,title,body,logo) => {
  console.log(title,logo)
  try {
    const response = await axios.post("api/send_notification", {
      title,
      id,
      body,
      logo
    });
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};

export const GetDashboardFilterdropdown = async (id = null, name = null) => {
  try {
    const response = await axios.get(`api/filter_data?type=${id}&name=${name}`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
export const GetParticularPost = async (id) => {
  try {
    const response = await axios.get(`api/get_post?post_id=${id}`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
export const GetParticularCandidatePost = async (id) => {
  try {
    const response = await axios.get(`api/particular_candidate_report_forcompany?id=${id}`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
///////////////////Partner//////////////////////
export const AddPartner = async (data) => {
  try {
    const response = await axios.post("partner/partner_register", data);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};

export const EditPartner = async (data) => {
  try {
    const response = await axios.patch("partner/edit_partner", data);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetAAllPartnerList = async () => {
  try {
    const response = await axios.get(`partner/partner_list`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetPartnerCarddate = async () => {
  try {
    const response = await axios.get(`partner/partner_list`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};

export const GetParticularPartner = async (data) => {
  try {
    const response = await axios.get(`partner/particular_partner?id=${data}`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
export const GetPartnerDetails = async (data) => {
  try {
    const response = await axios.get(`partner/partner_candidate_details?id=${data}`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const DeleteParticularPartner = async (data) => {
  try {
    const response = await axios.patch("partner/delete_partner", {
      id: data,
    });
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
///////////////////USER//////////////////////////
export const AddUser = async (data) => {
  try {
    const response = await axios.post("api/add_users", data);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};


export const GetAAllUserList = async () => {
  try {
    const response = await axios.get(`/api/get_all_users`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};

export const GetParticularUser = async (data) => {
  try {
    const response = await axios.get(`api/get_user?user_id=${data}`);
    //console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const EditUser = async (data) => {
  try {
    const response = await axios.patch("api/edit_users", data);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const DeleteParticularUser = async (data) => {
  try {
    const response = await axios.patch("api/delete_users", {
      user_id: data,
    });
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};

/////////////////////////Applicant Page////////////////////////////////

export const SendEmailToApplicant = async (data) => {
  try {
    const response = await axios.post("api/send_mail", data);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetVisitedTime = async (data) => {
  try {
    const response = await axios.post("api/visited_details", data);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const SelectRejectApp = async (data) => {
  try {
    const response = await axios.patch(
      "api/candidate_application_status",
      data
    );
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};

/////////////////////////////////Settings/////////////////////////
export const GetAccountDetails = async () => {
  try {
    const response = await axios.get("api/get_company_details");
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
////////////////////////////////////////////////////////////////
export const GetparticularCandidatedata = async (post_id, applicant_id) => {
  try {
    const response = await axios.get(
      `/api/get_particular_candidates_detail?post_id=${post_id}&applicant_id=${applicant_id}`
    );
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetPostListData = async () => {
  try {
    const response = await axios.get(
      `/api/get_all_subpost_titles`
    );
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetLinkidindata = async () => {
  try {
    const response = await axios.get(`/api/linkedinprofile`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
////////////////////////LOB///////////////////////////
export const AddNewCompany = async (data) => {
  try {
    const response = await axios.post("api/add_new_company", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const EditNewCompany = async (data) => {
  try {
    const response = await axios.patch("api/edit_sub_company", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};

export const GetAAllcompanyList = async () => {
  try {
    const response = await axios.get(`/api/get_sub_company_list`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const SelectCompany = async () => {
  try {
    const response = await axios.get(`/api/get_Company_list`);
    //console.log(response);
    return response;
  } catch (err) {
    //console.log(err);
    return err.response;
  }
};
export const GetParticularCompany = async (id) => {
  try {
    const response = await axios.get(`/api/get_particular_SubCompany?id=${id}`);
    // console.log(response);
    return response;
  } catch (err) {
    // console.log(err);
    return err.response;
  }
};
export const DeleteParticularcompany = async (id) => {
  try {
    const response = await axios.patch(`/api/delete_sub_company?id=${id}`);
    // console.log(response);
    return response;
  } catch (err) {
    // console.log(err);
    return err.response;
  }
};

  //////////////////////////////New??????????????????????
  export const GetParticularCompanyPost = async (id,searchParams) => {
    try {
      const response = await axios.get(`/api/sub_company_post?company_sub_id=${id}&${searchParams.toString()}`);
      // console.log(response);
      return response;
    } catch (err) {
      // console.log(err);
      return err.response;
    }
  };