import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbar,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
  gridClasses,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import Groups2Icon from "@mui/icons-material/Groups2";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import PlaylistPlayRoundedIcon from "@mui/icons-material/PlaylistPlayRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import Badge from "@mui/material/Badge";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Container,
  Fab,
  InputLabel,
  ListItemIcon,
  Select,
} from "@mui/material";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import Paper from "@mui/material/Paper";
import DatePicker from "react-datepicker";
import styles from "./Company.module.css";
import "react-datepicker/dist/react-datepicker.css";
import IconButton from "@mui/material/IconButton";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ListItemText from "@mui/material/ListItemText";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import Dialogmodal from "../../Modal/Dialogmodal";
import PagesRoundedIcon from "@mui/icons-material/PagesRounded";
import ThumbDownAltRoundedIcon from "@mui/icons-material/ThumbDownAltRounded";
import { AllDataProvider } from "../../../DataProvider/DataProvider";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import { AdditionalUses } from "../../AdditionalUses/AdditionalUses";
import { Tooltip } from "@mui/material";
import {
  captionStyle,
  CardDatas,
  gradienterror,
  gradientPrimary,
  gradientsuccess,
  gradientWarning,
  RadiusProp,
  ShadowProp,
  StyledCardBox,
  StyledCardData,
  StyledCardTypo,
  StyledReportPaper,
} from "../../../../Theme";
// import {
//   GetParticularCompanyPost,
//   GetParticularPost,
//   GetParticularUser,
//   UserPostStatus,
// } from "../../AxiosFetch/AllFetchMethod";
import PostDetails from "../PostDetails/PostDetails";
import {
  GetParticularCompanyPost,
  GetParticularPost,
} from "../../AxiosFetch/AllFetchMethod";

const getSelectedRowsToExport = ({ apiRef }) => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }

  return gridFilteredSortedRowIdsSelector(apiRef);
};

const CompanyPost = ({ companyid, user_id }) => {
  const theme = useTheme();

  // console.log(companyid, user_id);
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const currentYear = new Date().getFullYear();
  const yearRangeStart = currentYear - 30;
  const yearRangeEnd = currentYear;
  const {
    openDialogModal,
    setopenDialogModal,
    postType,
    Notifi,
    setNotifi,
    setMessage,
    setPostType,
    userRecall,
    roleLogin,
    setParticularUser,
    ParticularPostData,
    setApplicantValue,
    setParticularPostData,
    setUserRecall,
    CustomNoRowsOverlay,
  } = AllDataProvider();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [UserList, setUserList] = useState([]);
  const [userId, setUserId] = useState("");
  const [data, setdata] = React.useState({});
  const [receiveddata, setReceivedData] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    Postedby: true,
  });
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams();
  const handleClick = (event, id) => {
    setUserId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let navfun = (data, id, domine, postname) => {
    setApplicantValue(data);
    navigate("/job posting/applicants", {
      state: {
        id,
        domine,
        postname,
      },
    });
  };
  ///////////////////////////////////////////
  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = window
        .moment(startDate, "ddd MMM DD YYYY HH:mm:ss ZZ")
        .format("YYYY-MM-DD");
      const formattedEndDate = window
        .moment(endDate, "ddd MMM DD YYYY HH:mm:ss ZZ")
        .format("YYYY-MM-DD");
      searchParams.set("start_date", formattedStartDate);
      searchParams.set("end_date", formattedEndDate);
    }
    (async () => {
      try {
        const { data, status } = await GetParticularCompanyPost(
          companyid,
          searchParams
        );
        if (status === 200) {
          setdata(data);
          setUserList(data.list_of_post);
          setReceivedData(true);
        } else {
          throw new Error(`Something went wrong ${status}`);
        }
      } catch (err) {
        setMessage(err.message);
        setNotifi(true);
        // console.log(err);
      }
    })();
  }, [userRecall, endDate]);
  let GetPost = async (id) => {
    try {
      let { status, data } = await GetParticularPost(id);
      // console.log(data);
      if (status === 200) {
        setParticularPostData(data);
        setopenDialogModal(true);
      } else {
        throw new Error(`Something went wrong ${status}`);
      }
    } catch (err) {
      setMessage(err.message);
      setNotifi(true);
      // console.log(err);
    }
  };
  //////////////////////////////////////////////
  // const handleScheduleClick = async (sdata, sid) => {
  //   try {
  //     const { data, status } = await UserPostStatus(
  //       sdata,
  //       sid,
  //       companyid,
  //       user_id
  //     );
  //     if (status === 200) {
  //       setUserRecall(!userRecall);
  //     } else {
  //       throw new Error(`Something went wrong ${status}`);
  //     }
  //   } catch (err) {
  //     setMessage(err.message);
  //     setNotifi(true);
  //     // console.log(err);
  //   }
  // };
  //////////////////////////////////////////////
  let columns = [
    {
      id: 1,
      field: "job_title",
      headerName: "Job Title ",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Tooltip title="View Post" arrow>
          <Typography
            sx={{
              cursor: "pointer",
              color: "primary.light",
              fontWeight: "500",
              letterSpacing: "0.5px",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            variant="body2"
            onClick={() => GetPost(row.id)}
          >
            {row.job_title}
          </Typography>
        </Tooltip>
      ),
    },
    {
      id: 2,
      field: "date",
      headerName: "Posted Date",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {row.date}
        </Typography>
      ),
    },
    {
      id: 3,
      field: "type",
      headerName: "Type",
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {row.type}
        </Typography>
      ),
    },
    {
      id: 3,
      field: "posted_by",
      headerName: "Posted By",
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {row.posted_by}
        </Typography>
      ),
    },
    {
      id: 4,
      field: "total_applied",
      headerName: "Candidates",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Tooltip
          title={Number(row.total_applied) > 0 ? "View Response" : ""}
          arrow
        >
          <IconButton
            onClick={() => {
              Number(row.total_applied) > 0 &&
                navfun(0, row.id, row.domine, row.job_title);
            }}
          >
            <Badge badgeContent={row.total_applied} color="secondary">
              <Groups2Icon />
            </Badge>
          </IconButton>
        </Tooltip>
      ),
    },
    {
      id: 3,
      field: "Hired",
      headerName: "Hired  ",
      headerClassName: "super-app-theme--header",
      width: 70,
      renderCell: ({ row }) => (
        <Box>
          <Tooltip
            title={Number(row.status_counts?.hired) > 0 ? "Hired" : ""}
            arrow
            onClick={() => {
              Number(row.status_counts?.hired) > 0 &&
                navfun(3, row.id, row.domine, row.job_title);
            }}
          >
            <Badge badgeContent={row.status_counts?.hired} color="secondary">
              <TaskAltRoundedIcon
                sx={{ color: "text.secondary", cursor: "pointer" }}
              />
            </Badge>
          </Tooltip>
        </Box>
      ),
    },
    {
      id: 3,
      field: "ShortList",
      headerName: "ShortList ",
      headerClassName: "super-app-theme--header",
      width: 70,
      renderCell: ({ row }) => (
        <Box>
          <Tooltip
            title={Number(row.status_counts?.inprocess) > 0 ? "ShortList" : ""}
            arrow
            onClick={() => {
              Number(row.status_counts?.inprocess) > 0 &&
                navfun(2, row.id, row.domine, row.job_title);
            }}
          >
            <Badge
              badgeContent={row.status_counts?.inprocess}
              color="secondary"
            >
              <PlaylistPlayRoundedIcon
                sx={{ color: "text.secondary", cursor: "pointer" }}
              />
            </Badge>
          </Tooltip>
        </Box>
      ),
    },
    {
      id: 13,
      field: "Reject",
      headerName: "Reject",
      headerClassName: "super-app-theme--header",
      width: 70,
      renderCell: ({ row }) => (
        <Box>
          <Tooltip
            title={Number(row.status_counts?.rejected) > 0 ? "Rejected" : ""}
            arrow
            onClick={() => {
              Number(row.status_counts?.rejected) > 0 &&
                navfun(4, row.id, row.domine, row.job_title);
            }}
          >
            <Badge badgeContent={row.status_counts?.rejected} color="secondary">
              <HighlightOffRoundedIcon
                sx={{ color: "text.secondary", cursor: "pointer" }}
              />
            </Badge>
          </Tooltip>
        </Box>
      ),
    },
    // {
    //   id: 6,
    //   field: "status_counts",
    //   headerName: "Actions",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: ({ row }) => (
    //     <FormControl fullWidth>
    //       <InputLabel id="demo-simple-select-label">Select</InputLabel>
    //       <Select
    //         size="small"
    //         labelId="demo-simple-select-label"
    //         id="demo-simple-select"
    //         value={row?.post_active_status}
    //         label="Select"
    //         onChange={(event) =>
    //           handleScheduleClick(event.target.value, row.id)
    //         }
    //         sx={{
    //           "& .MuiSelect-select": {
    //             display: "flex",
    //             alignItems: "center",
    //           },
    //         }}
    //       >
    //         <MenuItem value={"Active"}>
    //           <ListItemIcon>
    //             <VerifiedUserIcon color="success" />
    //           </ListItemIcon>
    //           Active
    //         </MenuItem>
    //         <MenuItem value={"In Active"}>
    //           <ListItemIcon>
    //             <PauseCircleFilledIcon color="error" />
    //           </ListItemIcon>
    //           In Active
    //         </MenuItem>
    //         <MenuItem value={"Block"}>
    //           <ListItemIcon>
    //             <FiberManualRecordIcon color="secondary" />
    //           </ListItemIcon>
    //           Block
    //         </MenuItem>
    //       </Select>
    //     </FormControl>
    //   ),
    // },

    // {
    //   id: 10,
    //   field: "Action",
    //   width: 150,
    //   renderCell: ({ row }) => (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Box>
    //         <Tooltip title="Actions" arrow>
    //           <IconButton
    //             onClick={(e) => handleClick(e, row.user_id)}
    //             size="small"
    //             sx={{ ml: 2 }}
    //             aria-controls={open ? "account-menu" : undefined}
    //             aria-haspopup="true"
    //             aria-expanded={open ? "true" : undefined}
    //           >
    //             <MoreVertIcon />
    //           </IconButton>
    //         </Tooltip>
    //       </Box>
    //     </Box>
    //   ),
    // },
  ];

  return (
    receiveddata && (
      <>
        {openDialogModal && (
          <Dialogmodal>
            <PostDetails Data={ParticularPostData} />
          </Dialogmodal>
        )}
        {Notifi && <AdditionalUses />}

        <Stack spacing={4}>
          <Box sx={{ padding: { md: "0 24px 0 24px", xs: "0" } }}>
            <Paper
              sx={{
                padding: "15px",
                boxShadow: ShadowProp,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 3,
              }}
            >
              <Box sx={{ width: { md: "30%", xs: "100%" } }}>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  isClearable={true}
                  withPortal
                  required
                  placeholderText="From dd/mm/yyyy To dd/mm/yyyy"
                  className={styles.DatePicker}
                  showYearDropdown
                  peekNextMonth
                  showMonthDropdown
                  yearDropdownItemNumber={30}
                  scrollableYearDropdown
                  minDate={new Date(yearRangeStart, 0, 1)} // Start from 30 years ago
                  maxDate={new Date(yearRangeEnd, 11, 31)} // End at the current year
                />
              </Box>
            </Paper>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              alignItems: "center",
              gap: 4,
            }}
          >
            <StyledCardData>
              <CardDatas
                sx={{
                  background: gradientWarning,
                }}
              >
                <PagesRoundedIcon fontSize="medium" />
              </CardDatas>
              <StyledCardBox>
                <Stack alignItems="end">
                  <Typography variant="h6">{data.total_post_count  || 0}</Typography>
                  <StyledCardTypo variant="body1" color="text.secondary">
                    Total Post
                  </StyledCardTypo>
                </Stack>
              </StyledCardBox>
            </StyledCardData>
            <StyledCardData>
              <CardDatas
                sx={{
                  background: gradientsuccess,
                }}
              >
                <HowToRegRoundedIcon fontSize="medium" />
              </CardDatas>
              <StyledCardBox>
                <Stack alignItems="end">
                  <Typography variant="h6">{data.total_applyed || 0}</Typography>
                  <StyledCardTypo variant="body1" color="text.secondary">
                    Candidate
                  </StyledCardTypo>
                </Stack>
              </StyledCardBox>
            </StyledCardData>
            <StyledCardData>
              <CardDatas
                sx={{
                  background: gradientsuccess,
                }}
              >
                <HowToRegRoundedIcon fontSize="medium" />
              </CardDatas>
              <StyledCardBox>
                <Stack alignItems="end">
                  <Typography variant="h6">{data.counts.hired || 0}</Typography>
                  <StyledCardTypo variant="body1" color="text.secondary">
                    Hired
                  </StyledCardTypo>
                </Stack>
              </StyledCardBox>
            </StyledCardData>

            <StyledCardData>
              <CardDatas
                sx={{
                  background: gradientWarning,
                }}
              >
                <SubjectRoundedIcon fontSize="medium" />
              </CardDatas>
              <StyledCardBox>
                <Stack alignItems="end">
                  <Typography variant="h6">{data.counts.inprocess || 0}</Typography>
                  <StyledCardTypo variant="body1" color="text.secondary">
                    ShortListed
                  </StyledCardTypo>
                </Stack>
              </StyledCardBox>
            </StyledCardData>

            <StyledCardData>
              <CardDatas
                sx={{
                  background: gradienterror,
                }}
              >
                <ThumbDownAltRoundedIcon fontSize="medium" />
              </CardDatas>
              <StyledCardBox>
                <Stack alignItems="end">
                  <Typography variant="h6">{data.counts.rejected || 0}</Typography>
                  <StyledCardTypo variant="body1" color="text.secondary">
                    Rejected
                  </StyledCardTypo>
                </Stack>
              </StyledCardBox>
            </StyledCardData>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                height: "550px",
                overflowX: "auto",
                width: isMdScreen ? "90%" : "100%",
                boxShadow: ShadowProp,
                borderRadius: RadiusProp,
                padding: "1rem",
              }}
            >
              <DataGrid
                disableRowSelectionOnClick
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                checkboxSelection
                pageSizeOptions={[5, 10, 25]}
                columns={columns.map((column) => ({
                  ...column,
                  sortable: false,
                }))}
                rows={UserList}
                slots={{
                  noRowsOverlay: () => CustomNoRowsOverlay(),
                  toolbar: () => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <GridToolbar />
                    </Box>
                  ),
                }}
                getRowHeight={() => "auto"}
                sx={{
                  [`& .${gridClasses.cell}`]: {
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                  },
                  border: "none",
                  // '& .MuiDataGrid-cell:hover': {
                  //   color: 'primary.main',
                  // },
                }}
              />
            </Box>
          </Box>
        </Stack>
      </>
    )
  );
};

export default CompanyPost;
