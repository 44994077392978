import {
    Box,
    Collapse,
    Divider,
    IconButton,
    Link,
    Stack,
    styled,
    Typography,
    Button,
    TextField,
    Fab,
    Paper,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import PropTypes from "prop-types";
  import Tabs from "@mui/material/Tabs";
  import Tab from "@mui/material/Tab";
  import { captionStyle, gradientPrimary, ShadowProp, StyledPaper } from "../../../../Theme";
  import Grid from "@mui/material/Grid";
  import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
  import LaunchIcon from "@mui/icons-material/Launch";
  import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
  import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
  import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
  import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
//   import CurrentPackages from "../Candidate/CandidatesPackages/CurrentPackages";
//   import PreviousPackages from "../Candidate/CandidatesPackages/PreviousPackages";
//   import CompanyUsers from "./CompanyUsers/CompanyUsers";
  import { AllDataProvider } from "../../../DataProvider/DataProvider";
  import { useLocation } from "react-router-dom";
  import CompanyPost from "../CompanyPost/CompanyPost";
  import ScrollToTopButton from "../../AdditionalUses/ScrollToTopButton";
import { GetParticularCompany } from "../../AxiosFetch/AllFetchMethod";
  
  //////////////////////////////////////////////////////
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  ///////////////////////////////////////////////////////
  
  const LobCompanies = () => {
    const {
      openDialogModal,
      setopenDialogModal,
      postType,
      Notifi,
      setNotifi,
      setMessage,
      setPostType,
      userRecall,
      
      setUserRecall,
    } = AllDataProvider();
    const {state:{id}} = useLocation()
    const [expanded, setExpanded] = useState(false);
    const [value, setValue] = React.useState(0);
    const [data, setdata] = React.useState({});
    const [receiveddata,setReceivedData] = useState(false)
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleToggle = () => {
      setExpanded(!expanded);
    };
    const FlexBox = styled(Box)({
      display: "flex",
      alignItems: "center",
      gap: 5,
    });
   
      useEffect(() => {
        (async () => {
          try {
            const { data, status } = await GetParticularCompany(id);
            if (status === 200) {
              setdata(data)
              setReceivedData(true)
            } else {
              throw new Error(`Something went wrong ${status}`);
            }
          } catch (err) {
            setMessage(err.message);
            setNotifi(true);
            // console.log(err);
          }
        })();
        
      }, [userRecall]);
    return (
      receiveddata && <>
      
      <Box sx={{ marginBottom:"15px" }}>
      <Fab
              size="small"
            sx={{background:gradientPrimary,color:"white"}}
              variant="extended"
              aria-label="add"
              onClick={() => window.history.back()}
            >
              <ArrowBackIcon  />
            </Fab>
          </Box>
        <Paper sx={{ boxShadow: ShadowProp,p:3}}>
          <Stack spacing={2}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ margin: "5px 0", width: "250px" }}>
                    <img
                      src={
                        data?.company_logo
                      }
                      alt=""
                      width="100%"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Stack spacing={3}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="h5">
                       {data?.company_name}
                      </Typography>
                      <IconButton onClick={()=>window.open(data?.company_website_link)}>
                        <LaunchIcon color="info" />
                      </IconButton>
                    </Box>
                    <FlexBox>
                      <FmdGoodOutlinedIcon fontSize="medium" />
                      <Typography variant="caption" sx={captionStyle}>
                        {data?.company_location}
                      </Typography>
                    </FlexBox>
  
                    {/* <FlexBox>
                      <PhoneOutlinedIcon fontSize="medium" />
                      <Typography variant="caption">{data?.mobile_no}</Typography>
                    </FlexBox> */}
                    <FlexBox>
                      <MarkunreadOutlinedIcon fontSize="medium" />
                      <Typography variant="caption" sx={{textTransform:"lowercase"}}>{data?.user_email}</Typography>
                    </FlexBox>
                    <Box>
                      <Typography variant="body1" component="div" gutterBottom>
                        {expanded ? data?.about_company : `${data?.about_company.slice(0, 200)}...`}{" "}
                        {data?.about_company.length > 160 && (
                          <Link
                            onClick={handleToggle}
                            component="button"
                            underline="hover"
                          >
                            {expanded ? "Read Less" : "Read More"}
                          </Link>
                        )}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Divider />
  
  
  
            {/* <Box
              sx={{
                maxWidth: "100vw",
                bgcolor: "background.paper",
                typography: "body1",
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                >
                  <Tab label="Current Package" {...a11yProps(0)} />
                  <Tab label="Previous Packages" {...a11yProps(1)} />
                  <Tab label="Queries" {...a11yProps(2)} />
                </Tabs>
              </Box>
  
              <CustomTabPanel value={value} index={0}>
                <CurrentPackages />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <PreviousPackages />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <CompanyQueries  companyid={id}/>
              </CustomTabPanel>
            </Box>
            <Divider /> */}
            <Typography variant="h6" gutterBottom>
              {" "}
              Company Post History
            </Typography>
            <CompanyPost companyid = {id} user_id={id}/>
            
          </Stack>
        </Paper>
      </>
    );
  };
  
  export default LobCompanies;
  