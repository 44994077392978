// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Toolbar,
// } from "@mui/material";
// import { TableVirtuoso } from "react-virtuoso";

// const Consultancy = () => {
//   const columns = [
//     { id: "name", label: "Name" },
//     { id: "age", label: "Age" },
//     { id: "city", label: "City" },
//   ];

//   const data = [
//     { id: 1, name: "John Doe", age: 30, city: "New York" },
//     { id: 2, name: "Jane Smith", age: 25, city: "San Francisco" },
//     // Add more rows here...
//   ];
//   const VirtuosoTableComponents = {
//     Scroller: React.forwardRef((props, ref) => (
//       <TableContainer component={Paper} {...props} ref={ref} />
//     )),
//     Table: (props) => (
//       <Table
//         {...props}
//         sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
//       />
//     ),
//     TableHead,
//     TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
//     TableBody: React.forwardRef((props, ref) => (
//       <TableBody {...props} ref={ref} />
//     )),
//   };
//   function fixedHeaderContent() {
//     return (
//       <TableRow>
//         {columns.map((column) => (
//           <TableCell
//             key={column.id}
//             variant="head"
//             align={column.numeric || false ? "right" : "left"}
//             style={{ width: column.width }}
//             sx={{
//               backgroundColor: "background.paper",
//             }}
//           >
//             {column.label}
//           </TableCell>
//         ))}
//       </TableRow>
//     );
//   }

//   function rowContent(_index, row) {
//     return (
//       <React.Fragment>
//         {columns.map((column) => {
//           return (
//             <TableCell
//               key={column.id}
//               align={column.numeric || false ? "right" : "left"}
//             >
//               {row[column.id]}
//             </TableCell>
//           );
//         })}
//       </React.Fragment>
//     );
//   }

//   return (
//     <>
//       <Container>
//         <Paper style={{ height: 400, width: "100%" }}>
//           <Toolbar>
//             <Typography variant="h6" id="tableTitle" component="div">
//               My Table
//             </Typography>
//           </Toolbar>
//           <TableVirtuoso
//             data={data}
//             components={VirtuosoTableComponents}
//             fixedHeaderContent={fixedHeaderContent}
//             itemContent={rowContent}
//           />
//         </Paper>
//       </Container>
//     </>
//   );
// };

// export default Consultancy;

import React, { useEffect, useState } from "react";

import {
  DataGrid,
  GridToolbar,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
  gridClasses,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import Dialogmodal from "../Modal/Dialogmodal";
import AddEditUsers from "../Users/AddEditUsers";
import Badge from "@mui/material/Badge";
import Groups2Icon from "@mui/icons-material/Groups2";
import { AllDataProvider } from "../../DataProvider/DataProvider";

import { AdditionalUses } from "../AdditionalUses/AdditionalUses";
import { Container, Tooltip } from "@mui/material";
import {
  captionStyle,
  CardDatas,
  gradientCandi,
  gradientPrimary,
  gradientrevenue,
  gradientsuccess,
  RadiusProp,
  ShadowProp,
  StyledCardBox,
  StyledCardData,
  StyledCardTypo,
} from "../../../Theme";
import AddEditPartner from "./AddEditPartner";
import {
  DeleteParticularPartner,
  GetAAllPartnerList,
  GetParticularPartner,
} from "../AxiosFetch/AllFetchMethod";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
const getSelectedRowsToExport = ({ apiRef }) => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }

  return gridFilteredSortedRowIdsSelector(apiRef);
};

const Consultancy = () => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const {
    CustomNoRowsOverlay,
    openDialogModal,
    setopenDialogModal,
    postType,
    Notifi,
    setNotifi,
    setMessage,
    setPostType,
    userRecall,
    setParticularPartner,
    setUserRecall,
  } = AllDataProvider();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [UserList, setUserList] = useState([]);
  const [userId, setUserId] = useState("");
  const [cardData, setcardData] = useState({});
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    // (async () => {
    //   try {
    //     let { status, data } = await GetPartnerCarddate();
    //     if (status === 200) {

    //       setcardData(data);
    //     } else {
    //       throw new Error(data.status);
    //     }
    //   } catch (err) {
    //     setMessage(err.message);
    //     setNotifi(true);
    //     //console.log(err);
    //   }
    // })();
    (async () => {
      try {
        const { data, status } = await GetAAllPartnerList();
        if (status === 200) {
          setUserList(data.datas);
          setcardData(data.card_data)
        } else {
          throw new Error(`Something went wrong ${status}`);
        }
      } catch (err) {
        setMessage(err.message);
        setNotifi(true);
        //console.log(err);
      }
    })();
  }, [userRecall]);

  const handleClick = (event, id) => {
    setUserId(id);
    setAnchorEl(event.currentTarget);
  };
  const EditUser = () => {
    setPostType("Edit");
    setopenDialogModal(true);
  };

  const EditDeleteuser = async (type) => {
    if (type === "Edit") {
      try {
        let { status, data } = await GetParticularPartner(userId);
        if (status === 200) {
          setParticularPartner(data);
          EditUser();
        } else {
          throw new Error(`Something went wrong ${status}`);
        }
      } catch (err) {
        setMessage(err.message);
        setNotifi(true);
        //console.log(err);
      }
    } else {
      try {
        let { status, data } = await DeleteParticularPartner(userId);
        if (status === 200) {
          setUserRecall(!userRecall);
          setMessage("Removed Successfully");
          setNotifi(true);
        } else {
          throw new Error(`Something went wrong ${status}`);
        }
      } catch (err) {
        setMessage(err.message);
        setNotifi(true);
        //console.log(err);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const AddButton = () => {
    const AddUsers = () => {
      setPostType("Add");
      setopenDialogModal(true);
    };

    return (
      <GridToolbarContainer>
        <Tooltip title="Add Partner" arrow>
          <Button
            sx={{ background: gradientPrimary }}
            variant="contained"
            onClick={AddUsers}
            startIcon={<GroupAddIcon />}
          >
            Add Partner
          </Button>
        </Tooltip>
      </GridToolbarContainer>
    );
  };

  let GetPost = async (id) => {
    navigate("/partner", {
      state: {
        id,
      },
    });
  };

  const formatValue = (num) => {
    if (num >= 100000) return `${num / 100000}L`;
    if (num >= 1000) return `${num / 1000}K`;
    return num;
  };
  let columns = [
    {
      id: 1,
      field: "partner_name",
      headerName: "PartnerName",
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Tooltip title="View Post" arrow>
          <Typography
            sx={{
              cursor: "pointer",
              color: "primary.light",
              fontWeight: "500",
              letterSpacing: "0.5px",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            variant="body2"
            onClick={() => GetPost(row.id)}
          >
            {row.partner_name}
          </Typography>
        </Tooltip>
      ),
      width: 150,
    },
    {
      id: 44,
      field: "partner_id",
      headerName: "Partner ID",
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {row.partner_id}
        </Typography>
      ),
      width: 150,
    },
    {
      id: 2,
      field: "partner_email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {row.partner_email}
        </Typography>
      ),
      width: 200,
    },
    {
      id: 2,
      field: "institute_name",
      headerName: "Institute Name",
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Typography
          variant="caption"
          sx={{
            ...captionStyle,
            whiteSpace: "normal",
            wordBreak: "break-word",
            lineHeight: "1.2",
          }}
        >
          {row.institute_name}
        </Typography>
      ),
      width: 200,
    },
    {
      id: 2,
      field: "website_link",
      headerName: "Website",
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Typography
          variant="caption"
          sx={{
            ...captionStyle,
            whiteSpace: "normal",
            wordBreak: "break-word",
            lineHeight: "1.2",
          }}
        >
          <a href={row?.website_link} target="_blank">
            {row?.website_link}
          </a>
        </Typography>
      ),
      width: 200,
    },

    {
      id: 3,
      field: "mobile_no",
      headerName: "Mobile No",
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {row.mobile_no}
        </Typography>
      ),
      width: 150,
    },
    {
      id: 2,
      field: "price",
      headerName: "Placement Price",
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
          {Number(row.price).toLocaleString("en-IN")}
        </Typography>
      ),
      width: 100,
    },
    {
      id: 2,
      field: "total_applied",
      headerName: "No Of Applicants",
      headerClassName: "super-app-theme--header",
      width: 100,
      renderCell: ({ row }) => (
        <IconButton>
          <Badge badgeContent={row.purchase_count} color="secondary">
            <Groups2Icon />
          </Badge>
        </IconButton>
      ),
    },
    {
      id: 2,
      field: "total_Price",
      headerName: "Total Revenue",
      headerClassName: "super-app-theme--header",
      width: 100,
      renderCell: ({ row }) => (
        <Typography variant="caption" sx={captionStyle}>
        {row?.price ? formatValue(Number(row?.price)*row.purchase_count) : 0}
      </Typography>
      ),
    },
    {
      id: 8,
      field: "partner_status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 100,
      renderCell: ({ row }) => (
        <>
          {row.partner_status ? (
            <Button
              sx={{ p: 0, background: gradientsuccess, fontWeight: 500 }}
              variant="contained"
              fontSize="small"
            >
              Active
            </Button>
          ) : (
            <Button
              sx={{ p: 0, fontWeight: 500 }}
              variant="contained"
              color="error"
              fontSize="small"
            >
              inActive
            </Button>
          )}
        </>
      ),
    },
    {
      id: 10,
      field: "Action",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Tooltip title="Actions" arrow>
              <IconButton
                onClick={(e) => handleClick(e, row.id)}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <Container>
      {openDialogModal && (
        <Dialogmodal>
          <AddEditPartner />
        </Dialogmodal>
      )}
      {Notifi && <AdditionalUses />}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => EditDeleteuser("Edit")}>
          <ListItemIcon>
            <ModeEditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => EditDeleteuser("Delete")}>
          <ListItemIcon>
            <DeleteOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Stack spacing={2}>
        <Box>
          <Typography variant="h6" gutterBottom>
            Placement Partners
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: 4,
          }}
        >
          <StyledCardData>
            <CardDatas
              sx={{
                background: gradientCandi,
              }}
            >
              <Groups2RoundedIcon fontSize="medium" />
            </CardDatas>
            <StyledCardBox>
              <Stack alignItems="end">
                <Typography variant="h6">
                  {cardData?.partners_count}
                </Typography>
                <StyledCardTypo variant="body1" color="text.secondary">
                  Partners
                </StyledCardTypo>
              </Stack>
            </StyledCardBox>
          </StyledCardData>
          <StyledCardData>
            <CardDatas
              sx={{
                background: gradientCandi,
              }}
            >
              <Groups2RoundedIcon fontSize="medium" />
            </CardDatas>
            <StyledCardBox>
              <Stack alignItems="end">
                <Typography variant="h6">
                  {cardData?.candidates_count}
                </Typography>
                <StyledCardTypo variant="body1" color="text.secondary">
                  Placement Purchased
                </StyledCardTypo>
              </Stack>
            </StyledCardBox>
          </StyledCardData>
          <StyledCardData>
            <CardDatas
              sx={{
                background: gradientrevenue,
              }}
            >
              <AccountBalanceWalletRoundedIcon fontSize="medium" />
            </CardDatas>
            <StyledCardBox>
              <Stack alignItems="end">
                <Typography variant="h6">{cardData?.Amount?formatValue(Number(cardData?.Amount)) :0 }</Typography>
                <StyledCardTypo variant="body1" color="text.secondary">
                  Purchased
                </StyledCardTypo>
              </Stack>
            </StyledCardBox>
          </StyledCardData>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              height: "600px",
              overflowX: "auto",
              width: isMdScreen ? "80vw" : "100vw",
              boxShadow: ShadowProp,
              borderRadius: RadiusProp,
              padding: "1rem",
            }}
          >
            <DataGrid
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              disableRowSelectionOnClick
              pageSizeOptions={[5, 10, 25]}
              checkboxSelection
              slotProps={{
                toolbar: {
                  printOptions: { getRowsToExport: getSelectedRowsToExport },
                },
              }}
              columns={columns.map((column) => ({
                ...column,
                sortable: false,
              }))}
              rows={UserList}
              slots={{
                noRowsOverlay: () => CustomNoRowsOverlay(),
                toolbar: () => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <GridToolbar />
                    <AddButton />
                  </Box>
                ),
              }}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
                border: "none",
              }}
              getRowHeight={() => "auto"}
            />
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};

export default Consultancy;
