import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
const PostDetails = ({
  Data: {
    post_id,
    job_title,
    job_type,
    type,
    work_mode,
    job_nature,
    job_responsibilities,
    // job_location,
    vacancy,
    minimum_experience,
    maximum_experience,
    job_description,
    key_skills,
    minimum_salary,
    maximum_salary,
    salary_per,
    time_line,
    benefits,
    mobile_no,
    email,
    post_country,
    post_state,
    post_city,
    advertise_country,
    advertise_state,
    advertise_city,
    stateCode,
    cityCode,
    AdvertisestateCode,
    AdvertisecityCode,
    date,
    show_email,
    show_contact,
    interview_questions,
    show_questions,
    domine,
  },
}) => {
  // console.log(interview_questions, "Data");
  return (
    <>
      <Container  sx={{ marginTop: "25px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              component="paper"
              boxShadow={0}
              borderRadius={2}
              bgcolor="background.paper"
              p={2}
              width="100%"
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" textAlign="center">
                    {job_title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <LocationOnOutlinedIcon />
                    <Typography variant="subtitle1">
                      {post_city.toString()}, {post_state}, {post_country}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <Stack spacing={0}>
                        <Stack direction="row" spacing={1}>
                          <WorkOutlineOutlinedIcon />
                          <Typography variant="subtitle1">
                            Experience
                          </Typography>
                        </Stack>
                        <Typography variant="caption" marginLeft={4}>
                          {`${minimum_experience} -${maximum_experience} Years `}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Stack spacing={0}>
                        <Stack direction="row" spacing={1}>
                          <LocalAtmOutlinedIcon />
                          <Typography variant="subtitle1">
                           Salary
                          </Typography>
                        </Stack>
                        <Typography variant="caption" marginLeft={4}>
                          {`${minimum_salary} - ${maximum_salary}  ${salary_per}`}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Stack spacing={0}>
                        <Stack direction="row" spacing={1}>
                          <HomeWorkOutlinedIcon />
                          <Typography variant="subtitle1">
                            {" "}
                            Work Mode
                          </Typography>
                        </Stack>
                        <Typography variant="caption" marginLeft={4}>
                          {`${work_mode}`}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction="row" spacing={1}>
                        <PeopleAltOutlinedIcon />
                        <Typography variant="subtitle1">
                          {`${vacancy}`} Vacancies
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
           <Grid item
              xs={12}
              component="paper"
              boxShadow={0}
              borderRadius={2}
              bgcolor="background.paper"
              p={2}
              marginTop="15px"
              width="100%" > 
          <Grid container spacing={2} >
          {job_description .length > 0 &&<Grid item xs={12}>
              <Typography variant="h6">Job Description</Typography>
            </Grid>}
             <Grid item xs={12}>
              <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
                {job_description}
              </Typography>
            </Grid>
           {job_responsibilities.length > 0 &&<Grid item xs={12}>
              <Typography variant="h6">Job Responsiblity</Typography>
            </Grid>}
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
                {job_responsibilities}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Key Skills</Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
              >
                {key_skills.map((e, i) => {
                  return <Chip key={i} label={e} variant="outlined" />;
                })}
              </Stack>
            </Grid>
            {benefits.length > 0 && <Grid item xs={12}>
              <Typography variant="h6">Benefits</Typography>
            </Grid>}
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
                {benefits}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Job Nature
              </Typography>
              <Typography variant="subtitle1">{job_nature}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Job Type
              </Typography>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
              >
                {job_type.map((e, i) => {
                  return <Chip key={i} label={e} variant="outlined" />;
                })}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {show_contact && (
                <>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="button">Contact Info</Typography>
                    <Typography variant="subtitle1">{mobile_no}</Typography>
                  </Stack>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {show_email && (
                <>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="button">Email Id</Typography>
                    <Typography variant="subtitle1">{email}</Typography>
                  </Stack>
                </>
              )}
            </Grid>

          </Grid>
           </Grid>
            {Object.keys(interview_questions).length > 0 && (
              <>
              <Grid item xs={12}   
             
              component="paper"
              boxShadow={0}
              borderRadius={2}
              bgcolor="background.paper"
              p={2}
              marginTop="15px"
              width="100%">
                <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography textAlign="center" variant="h5">
                    Recruiter Questions
                  </Typography>
                </Grid>
                {interview_questions.relocate && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Relocate
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        {` 1. ${
                          interview_questions.relocate.question
                        } Prefered (${interview_questions.relocate.Showdata.toString()})`}
                      </Typography>
                    </Grid>
                  </>
                )}
                {interview_questions.minEducation && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Education
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        {`1. ${
                          interview_questions.minEducation.question
                        } Prefered (${interview_questions.minEducation.Showdata.toString()})`}
                      </Typography>
                    </Grid>
                  </>
                )}
                {interview_questions.ShiftTime && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Shift Timing
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        {`1. ${
                          interview_questions.ShiftTime.question
                        } Prefered (${interview_questions.ShiftTime.Showdata.toString()})`}
                      </Typography>
                    </Grid>
                  </>
                )}
                {interview_questions.certificate && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Certification
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {interview_questions.certificate && (
                        <ol>
                          {interview_questions.certificate.map((e) => {
                            return (
                              <li>
                                <Typography variant="subtitle1" gutterBottom>
                                  {`${e.question} ${
                                    e.Showdata.length > 0
                                      ? `(${e.Showdata})`
                                      : ""
                                  } `}
                                </Typography>
                              </li>
                            );
                          })}
                        </ol>
                      )}
                    </Grid>
                  </>
                )}
                {interview_questions.experience && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Experience
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {interview_questions.experience && (
                        <ol>
                          {interview_questions.experience.map((e) => {
                            return (
                              <li>
                                <Typography variant="subtitle1" gutterBottom>
                                  {`${e.question} ${
                                    e.Showdata.length > 0
                                      ? `Prefered (${e.Showdata})`
                                      : ""
                                  } `}
                                </Typography>
                              </li>
                            );
                          })}
                        </ol>
                      )}
                    </Grid>
                  </>
                )}
                {interview_questions.language && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Language
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {interview_questions.language && (
                        <ol>
                          {interview_questions.language.map((e) => {
                            return (
                              <li>
                                <Typography variant="subtitle1" gutterBottom>
                                  {`${e.question} ${
                                    e.Showdata.length > 0
                                      ? `(${e.Showdata})`
                                      : ""
                                  } `}
                                </Typography>
                              </li>
                            );
                          })}
                        </ol>
                      )}
                    </Grid>
                  </>
                )}
                {interview_questions.CustomQuestions && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Custom Questions
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {interview_questions.CustomQuestions && (
                        <ol>
                          {interview_questions.CustomQuestions.map((e) => {
                            return (
                              <li>
                                <Typography variant="subtitle1" gutterBottom>
                                  {`${e.question} ${
                                    e.Showdata.length > 0
                                      ? `(${e.Showdata})`
                                      : ""
                                  } `}
                                </Typography>
                              </li>
                            );
                          })}
                        </ol>
                      )}
                    </Grid>
                  </>
                )}
                </Grid>

              </Grid>
                
                
              </>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default PostDetails;
